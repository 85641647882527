// Copyright @yucwang 2021

import { graphql, Link, PageProps } from "gatsby"
import * as React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image" 
import { Helmet } from "react-helmet"
import MainLayout from "../components/layout"
import * as IndexPageStyle from "./index.module.css"

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string
        title: string
        contacts: {
          facebook: string
          telegram: string
          question_box: string
          github: string
        }
      }
    }
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        siteName
        title
        contacts {
          facebook
          telegram
          question_box
          github
        }
      }
    }
  }
`

export default class IndexPage extends React.Component<IndexPageProps> {
  readonly hello = `Hello`
  public render() {
    const siteTitle = this.props.data.site.siteMetadata.title;
    const facebookUrl = this.props.data.site.siteMetadata.contacts.facebook;
    const githubUrl = this.props.data.site.siteMetadata.contacts.github;
    const telegramUrl = this.props.data.site.siteMetadata.contacts.telegram;
    const questionBoxUrl = this.props.data.site.siteMetadata.contacts.question_box;

    return (
      <MainLayout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ siteTitle }</title>
        </Helmet>
        <div className={ IndexPageStyle.contentContainer }>
          <StaticImage 
              className={ IndexPageStyle.logo }
              style={{height: "192px", width: "192px", borderRadius: "50%" }} 
              src="../../assets/logo-512x512.png" />
          <div className={ IndexPageStyle.socialMediaContainer }>
            <Link className={ IndexPageStyle.socialMediaItem } to={ facebookUrl }>
              <StaticImage 
                alt="facebook"
                style={{height: "48px", width: "48px"}} 
                src="../../assets/facebook.svg" />
            </Link>
            <Link className={ IndexPageStyle.socialMediaItem } to={ telegramUrl }>
              <StaticImage 
                alt="telegram"
                style={{height: "48px", width: "48px"}} 
                src="../../assets/telegram.svg" />
            </Link>
            <Link className={ IndexPageStyle.socialMediaItem } to={ githubUrl }>
              <StaticImage 
                alt="github"
                style={{height: "48px", width: "48px"}} 
                src="../../assets/github.svg" />
            </Link>
            <Link className={ IndexPageStyle.socialMediaItem } to={ questionBoxUrl }>
              <StaticImage 
                alt="question_box"
                style={{height: "48px", width: "48px"}} 
                src="../../assets/question_box.svg" />
            </Link>
            <Link className={ IndexPageStyle.socialMediaItem } to="/">
              <StaticImage 
                alt="cv"
                style={{height: "48px", width: "48px"}} 
                src="../../assets/cv.svg" />
            </Link>
          </div>
        </div>
      </MainLayout>
    )
  }
}
